
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    to: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false
    }
  },
});
